import {
  FC,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import Link from "next/link";

import List from "apps/website/components/base/List/List";
import ListItem from "apps/website/components/base/ListItem/ListItem";
import {
  HeaderLinkType,
  IHeaderLinkChild,
} from "apps/website/components/base/Button/Button.map";
import useWindowSize from "apps/website/hooks/useWindowSize";
import IconButton from "apps/website/components/base/IconButton/IconButton";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Tag from "apps/website/components/base/Tag/Tag";
import { themeRootClassMap } from "apps/website/maps/Theme.map";
import { useAuth } from "@auth/client-sdk-react";

import NavigationItemChildrenAnchorTag from "./NavigationItemChildrenAnchorTag";
import NavigationItemChildrenAnchorImage from "./NavigationItemChildrenAnchorImage";

export interface INavigationItemChildren {
  childrenItems?: IHeaderLinkChild[];
  isChildrenOpen?: boolean;
  type?: HeaderLinkType;
  disableToggle?: boolean;
}

const NavigationItemChildren: FC<INavigationItemChildren> = ({ childrenItems, isChildrenOpen, type = "default", disableToggle }) => {
  const { windowSize, isActiveBreakpointAbove } = useWindowSize();
  const childrenWrapperBody = useRef<HTMLDivElement>(null);
  const [ childrenWrapperHeight, setChildrenWrapperHeight ] = useState("0px");
  const { logout } = useAuth();

  const isLocalPath = (path: string) => path.startsWith("/") && !path.startsWith("/manage-plan");

  useEffect(() => {
    setChildrenWrapperHeight(`${childrenWrapperBody.current?.offsetHeight}px`);
  }, [ windowSize ]);

  return (
    <>
      { !!childrenItems?.length && (
        <div
          style={{ "--children-wrapper-height": childrenWrapperHeight } as React.CSSProperties}
          className={type === "authMenu" ? "relative" : ""}
        >
          { type === "default" && (
            <div className={`${themeRootClassMap["light-grey"]} w-[200vw] transform -translate-x-1/2 xl:mt-7 absolute left-0 transition-all duration-500 hidden xl:block xl:h-[0px] xl:group-focus-within:h-[var(--children-wrapper-height)] xl:group-hover:h-[var(--children-wrapper-height)]`}></div>
          ) }
          { type === "authMenu" && (
            <div className={`${themeRootClassMap.dark} absolute hidden xl:block xl:mt-7 xl:h-[0px] xl:group-focus-within:h-[var(--children-wrapper-height)] xl:group-hover:h-[var(--children-wrapper-height)]`}></div>
          ) }
          <div
            data-theme={type === "default" || isActiveBreakpointAbove("xl") ? "dark" : "light-grey"}
            className={`transition-all overflow-y-clip ease-in-out supports-[overflow:clip]:duration-500 xl:absolute xl:invisible xl:max-h-[0px] xl:group-focus-within:visible xl:group-focus-within:max-h-[var(--children-wrapper-height)] xl:group-hover:visible xl:group-hover:max-h-[var(--children-wrapper-height)] ${type === "authMenu" ? "xl:right-0" : "xl:left-0"} ${isChildrenOpen ? "visible max-h-[var(--children-wrapper-height)]" : "invisible max-h-[0px]"}`}
          >
            <div ref={childrenWrapperBody} className={type === "authMenu" ? "xl:pt-7" : "xl:pt-9"}>
              <div className={"relative block"}>
                { type === "default" && (
                  <List direction={{ default: "column", xl: "row" }} className="relative z-10 md:w-[704px] lg:w-[960px] xl:w-[1216px] 2xl:w-[1368px] xl:space-x-4">
                    { childrenItems.map((child) => (
                      <ListItem key={child.id} direction={{ xl: "column" }} className="ml-4 py-4 border-b border-solid border-dark-grey xl:border-b-0 xl:ml-0 xl:max-w-[25%] xl:min-w-[20%] xl:pb-4 xl:box-border xl:flex-1">
                        <div className="xl:bg-white xl:w-full xl:box-border xl:p-4 xl:pb-0 xl:opacity-0 xl:group-focus-within:opacity-100 xl:group-hover:opacity-100 xl:transition-opacity xl:duration-500 xl:delay-0 xl:group-focus-within:delay-500 xl:group-hover:delay-500">
                          <div className="flex justify-between items-center">
                            { isLocalPath(child.to) ? (
                              <>
                                <Link href={child.to} legacyBehavior>
                                  <NavigationItemChildrenAnchorTag child={child} />
                                </Link>
                              </>
                            ) : (
                              <>
                                <NavigationItemChildrenAnchorTag child={child} />
                              </>
                            ) }
                            { child.tag && (
                              <Tag theme="brand" className="rotate-2 xl:-translate-y-2 ml-2 xl:ml-0">{ child.tag }</Tag>
                            ) }
                          </div>
                          { child.image && (
                            <>
                              { isLocalPath(child.to) ? (
                                <Link href={child.to} legacyBehavior>
                                  <NavigationItemChildrenAnchorImage child={child} />
                                </Link>
                              ) : (
                                <NavigationItemChildrenAnchorImage child={child} />
                              ) }
                            </>
                          ) }
                          <div className="hidden xl:block xl:border-t xl:border-solid xl:border-black">
                            <IconButton
                              to={child.to}
                              icon="arrowRightCircle"
                              size="xsmall"
                              textSize={legacySizeCollectionMap.sm}
                              label={`See more about ${child.name}`}
                              tabIndex={-1}
                              justify="default"
                            >
                              { child.seeMoreText ? child.seeMoreText : "See more" }
                            </IconButton>
                          </div>
                        </div>
                      </ListItem>
                    )) }
                  </List>
                ) }
                { type === "authMenu" && (
                  <List direction={{ default: "column" }} className={`relative z-10 px-4 xl:pt-4 min-w-fit xl:bg-black ${disableToggle && "border-b border-solid border-black mb-4"}`}>
                    { childrenItems.map((child) => (
                      <ListItem key={child.id} className={`ml-4 py-4 border-b border-solid border-dark-grey xl:ml-0 xl:py-0 xl:border-none ${disableToggle && "last:border-none"}`}>
                        { isLocalPath(child.to) ? (
                          <>
                            { child.type === "logout" ? (
                              <NavigationItemChildrenAnchorTag child={child} onClick={logout} />
                            ) : (
                              <Link href={child.to} legacyBehavior>
                                <NavigationItemChildrenAnchorTag child={child} />
                              </Link>
                            ) }
                          </>
                        ) : (
                          <>
                            <NavigationItemChildrenAnchorTag child={child} />
                          </>
                        ) }
                      </ListItem>
                    )) }
                  </List>
                ) }

              </div>
            </div>
          </div>
        </div>
      ) }
    </>
  );
};

export default memo(NavigationItemChildren);
